.container-shell {
    display: grid;
    grid-template-columns: auto minmax(500px, 4fr);
    justify-content: stretch;
    min-height: 100%;
  }
  
  .container-shell-children {
    display: flex;
    grid-column-start: 2;
    align-items: flex-start;
    justify-content: center;
    position:relative;
    width: 100%;
  }
  
  .container-shell-sidebar {
    grid-column-start: 1;
    height: 100vh;
    position: sticky;
    top: 0;
  }

  .container-children{
    display:flex;
    justify-content: center;
    flex:1;
  }


   .container-children>div{
    padding:0;

  }

  @media(min-width: 425px) {
    .container-children>div{
      min-width:410px;
  
    }
  }
  @media(min-width: 768px) {
    .container-children>div{
      min-width:756.4px;
  
    }
  }

  @media(min-width: 1024px) {
    .container-children>div{
      min-width:1008.64px;
  
    }
  }

  @media(min-width: 1440px) {
    .container-children>div{
      min-width:1418.4px;
  
    }
  }